<template>
  <div class="card mb-3 h-100">
    <a :href="item.link" target="_blank">
      <img :src="item.image" class="card-img-top" :alt="item.title">
    </a>
    <div class="card-body d-flex flex-column">
      <a :href="item.link" target="_blank" class="btn btn-custom mb-2">Link zu Amazon</a>
      <h5 class="card-title">{{ item.title }}</h5>
      <p class="card-text">{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  }
}
</script>

<style scoped>
.card-img-top {
  cursor: pointer;
  height: 170px !important;
}
</style>